import React from "react";
import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/common/Navbar";
import { Footer } from "./components/layout/Footer";
import { Home } from "./components/pages/home";
import { About } from "./components/pages/aboutUs";
import "./components/styles/global.scss";
import { Products } from "./components/pages/products";
import { Services } from "./components/pages/services";
import { Contact } from "./components/pages/contactus";
import { Blog } from "./components/pages/blog";
import { BlogDetails } from "./components/pages/blogDetails";
import { NewsArticle } from "./components/pages/newsArticle";
import { NewsDetails } from "./components/pages/newsDetails";
import { DoorRTU } from "./components/pages/products/pages/DoorSmartRTU";
import { MiniScadaControlPanel } from "./components/pages/products/pages/MiniScadaControlPanel";
import { MillMachinery } from "./components/pages/products/pages/MillMachinery";
import { ActuatorValve } from "./components/pages/products/pages/ActuatorValve";
import { SmartIntegratedPump } from "./components/pages/products/pages/SmartIntegratedPump";
import { Gallery } from "./components/pages/gallery";
import { Leadership } from "./components/pages/leadership";
import ScrollToTopButton from "./components/common/ScrollButton";
import { Carrer } from "./components/pages/carrer";
import { SolutionsByIndustry } from "./components/pages/solutionsbyindustry";
import { SolutionsByApplication } from "./components/pages/solutionbyApplication";
import { PrivacyPolicy } from "./components/pages/privacyPolicy";
import { UnderMaintenance } from "./components/pages/underMaintenance";
import ScrollToTop from "./components/ScrollToTop";
import { AdvancedPLCRTU } from "./components/pages/products/pages/AdvancedPLCRTU";
import { VAEIOTRTU } from "./components/pages/products/pages/VAEIOTRTU";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
export const App = () => {
  console.log("App loaded");
  return (
    <div className="app">
      <HashRouter basename="">
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route
            path="/products/machinary-module"
            element={<MillMachinery />}
          />
          <Route
            path="/solutionsbyindustry"
            element={<SolutionsByIndustry />}
          />
          <Route
            path="/solutionbyApplication"
            element={<SolutionsByApplication />}
          />
          <Route
            path="/products/mini-scada-control-panel"
            element={<MiniScadaControlPanel />}
          />
          <Route
            path="/products/smart-integrated-pump"
            element={<SmartIntegratedPump />}
          />
          <Route path="/carrer" element={<Carrer />} />
          <Route
            path="/products/actuator-valve-control-panel"
            element={<ActuatorValve />}
          />
          <Route
            path="/products/advanced-plc-rtu"
            element={<AdvancedPLCRTU />}
          />
          <Route path="/products/vae-iot-rtu" element={<VAEIOTRTU />} />
          <Route path="/products/door-smart-rtu" element={<DoorRTU />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          {/* <Route path="/news-article" element={<NewsArticle />} /> */}
          {/* <Route path="/news-details" element={<NewsDetails />} /> */}
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/leadership" exact="true" element={<Leadership />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/under-maintenance" element={<UnderMaintenance />} />
          <Route path="/download" element={<UnderMaintenance />} />
        </Routes>
        <ScrollToTopButton />
        <Footer />
        <ToastContainer />
      </HashRouter>
    </div>
  );
};
