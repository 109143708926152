import React, { useState } from "react";
import blogImg1 from "../../assets/images/products/sensorModule.jpg";
import blogImg2 from "../../assets/images/products/IOTModule.jpg";
import blogImg3 from "../../assets/images/products/standardInterfacingModule.jpg";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
import blog1 from "../../assets/images/blog/blog1.png";
import blog2 from "../../assets/images/blog/blog2.jpg";
import blog3 from "../../assets/images/blog/blog3.jpg";
import blog4 from "../../assets/images/blog/blog4.jpg";
import blog5 from "../../assets/images/blog/blog5.png";

const blogs = [
  {
    id: 1,
    title: "IoT in Industrial Automation: Transforming Real-Time Monitoring",
    subtitle:
      "The Internet of Things (IoT) has reshaped how industries manage automation, bringing forth new efficiencies...",
    timeStamp: "10 Jan, 2024",
    image: blog1,
  },
  {
    id: 2,
    title: "Smart Pump Control Panels: Enhancing Efficiency and Reliability",
    subtitle:
      "Efficient pump management is essential in industries like water management, wastewater treatment,...",
    timeStamp: "18 May, 2024",
    image: blog2,
  },
  {
    id: 3,
    title: "Essential Safety Measures for Electrical Control Panels",
    subtitle:
      "Electrical control panels are integral to the operation of industrial equipment, manufacturing lines, and other systems...",
    timeStamp: "11 August, 2024",
    image: blog3,
  },
  {
    id: 4,
    title: "The Role of Automation in Modern Industrial Control Panels",
    subtitle:
      "As industries increasingly rely on automation to boost productivity and efficiency, industrial control panels have evolved...",
    timeStamp: "25 NOV, 2024",
    image: blog4,
  },
  {
    id: 5,
    title: "Understanding MODBUS: A Comprehensive Guide",
    subtitle:
      "MODBUS is a robust and widely adopted communication protocol that has become the backbone of industrial automation systems...",
    timeStamp: "03 FEB, 2025",
    image: blog5,
  },
];
export const Bloglisting = () => {
  const [activePage, setActivePage] = useState(1);
  const blogsPerPage = 6;

  // Calculate the total number of pages
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  // Get the blogs for the current page
  const displayedBlogs = blogs.slice(
    (activePage - 1) * blogsPerPage,
    activePage * blogsPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  return (
    <div className="blog-list-wrap py-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-12 text-center">
            <h2 className="fs-2 underline underline-heading texts text-justify">
              Our Largest News Blog
            </h2>
          </div>
        </div>
        <div className="row blog-row">
          {displayedBlogs.map((blog) => (
            <div key={blog.id} className="col-12 col-lg-4 col-md-6">
              <div className="card blog-card mb-4">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blog.image}
                />

                <div className="card-body blog-card-body">
                  <small>{blog.timeStamp}</small>
                  <h5 className="card-title-text blog-card-title">
                    <Link to={`/blog-details?id=${blog.id}`}>{blog.title}</Link>
                  </h5>
                  <p className="card-text blog-card-text">{blog.subtitle}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-lg-12">
            <div className="mt-4">
              {totalPages > 1 && ( // Conditionally render pagination
                <Pagination>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (pageNumber) => (
                      <Pagination.Item
                        key={pageNumber}
                        active={pageNumber === activePage}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </Pagination.Item>
                    )
                  )}
                </Pagination>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
